@use '../../../assets/scss/globals/' as *;
@use '../../../assets/scss/util/' as *;

.video {
  &__wrapper {
    max-width: 1846px;
    width: 100%;
    height: 90vh;
    margin: 10px auto;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.controls {
  background-color: $c-primary;
  padding: 12px 50px;
  position: fixed;
  width: 100%;
  bottom: 0;
  &__control-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__button-group {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  &__buttons {
    button {
      text-align: center;
      padding: 0 15px;
      position: relative;
      border-color: transparent !important;
      svg {
        color: var(--white);
        width: 25px;
        height: 25px;
        margin-bottom: 5px;
      }
      span {
        display: block;
        font-size: 12px;
        color: var(--white);
      }
      &:hover,
      &:focus {
        text-decoration: none !important;
        background-color: #d4adff;
      }
      .badge {
        background-color: var(--white) !important;
        color: $c-primary !important;
        width: 15px;
        height: 15px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 5px;
        top: -5px;
      }
    }
    button.active {
      
      text-decoration: none !important;
        background-color: #d4adff;
      svg {
        fill: #ff0;
      }
      span {
        color: #ff0;
      }
    }
  }
  &__end-class {
    .btn-light {
      background-color: var(--white);
      color: $c-danger;
      font-size: 22px;
      font-weight: 500;
      &:hover {
        background-color: $c-danger;
        border: 1px solid $c-danger;
        color: var(--white);
      }
    }
  }
}
.chat-box {
  .offcanvas-header {
    background-color: $c-primary;
    color: var(--white);
    padding: 5px 10px;
    border-radius: 5px 5px 0px 0px;
    font-size: 18px;
    font-weight: 500;
  }
  .offcanvas-body {
    padding: 0;
  }
  .tgl-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f6f6f6;
    padding: 8px 15px;
    margin: 20px;
    p {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
    }
    .form-check-input {
      width: 60px;
      margin: 0;
    }
    .form-check-input:checked {
      background-color: $c-success !important;
      border: 1px solid $c-success !important;
    }
  }
  .msg-box {
    width: 100%;
    max-width: 400px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 15px 20px 40px;
    background-color: var(--white);
    position: fixed;
    bottom: 0;
    box-shadow: 5px 0 5px #ccc;
    &__chat-input {
      position: relative;
      width: 90%;
      input {
        background-color: #f5f5f5;
        border: none;
        border-radius: 28px !important;
        height: 50px;
      }
      svg {
        fill: $c-primary;
        position: absolute;
        right: 20px;
        width: 25px;
        height: 25px;
        top: 12px;
      }
    }
    &__send-file {
      width: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        fill: $c-primary;
        width: 35px;
        height: 35px;
      }
    }
  }
}

.file-upload {
  width: 21.5%;
  left: 79.2% !important;
  .offcanvas-body {
    padding-top: 0;
    padding-bottom: 0;
  }
  ul {
    list-style: none;
    padding: 0;
    li {
      border-bottom: 1px solid #ededed;
      .btn-link {
        text-decoration: none;
        padding: 15px 0;
        svg {
          fill: $c-primary;
          width: 25px;
          height: 25px;
        }
        span {
          color: #7e7e7e;
          font-size: 20px;
          font-weight: 400;
          padding-left: 10px;
        }
      }
    }
  }
}

.offcanvas-backdrop.show,
.modal-backdrop.show {
  opacity: 0 !important;
  display: none !important;
}

.question-box {
  .modal-dialog {
    max-width: 800px;
  }
  .modal-header {
    background-color: $c-primary;
    color: var(--white);
  }
}

// Media Query Starts

@media (max-width: 414px) {
  .controls {
    padding: 12px 0;
    overflow-y: scroll;
    &__button-group {
      gap: 0;
    }
    &__buttons {
      button {
        span {
          width: 75px;
        }
        .badge {
          right: 15px;
        }
      }
    }
    &__end-class {
      .btn-light {
        padding: 5px 10px;
        margin: 0 10px;
        font-size: 14px;
        width: 85px;
      }
    }
  }
}
