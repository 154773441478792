@use '../util' as *;

.btn {
  padding: 8px 23px 8px 23px;
  font-family: var(--roboto) !important;
  --bs-btn-active-color: #fff !important;
  --bs-btn-hover-color: #fff !important;
}

.btn-primary {
  --bs-btn-color: #fff !important;
  --bs-btn-bg: var(--primary) !important;
  --bs-btn-border-color: var(--primary) !important;
  --bs-btn-hover-border-color: var(--primary-400) !important;
  --bs-btn-hover-bg: var(--primary-400) !important;
}

.btn-secondary {
  --bs-btn-color: #fff !important;
  --bs-btn-bg: var(--secondary) !important;
  --bs-btn-border-color: var(--secondary) !important;
  --bs-btn-hover-border-color: var(--secondary-400) !important;
  --bs-btn-hover-bg: var(--secondary-400) !important;
}

.btn-success {
  --bs-btn-color: #fff !important;

  --bs-btn-bg: var(--success) !important;
  --bs-btn-border-color: var(--success) !important;
  --bs-btn-hover-border-color: var(--success-400) !important;
  --bs-btn-hover-bg: var(--success-400) !important;
}

.btn-info {
  --bs-btn-color: #fff !important;

  --bs-btn-bg: var(--info) !important;
  --bs-btn-border-color: var(--info) !important;
  --bs-btn-hover-border-color: var(--info-400) !important;
  --bs-btn-hover-bg: var(--info-400) !important;
}

.btn-danger {
  --bs-btn-color: #fff !important;

  --bs-btn-bg: var(--danger) !important;
  --bs-btn-border-color: var(--danger) !important;
  --bs-btn-hover-border-color: var(--danger-400) !important;
  --bs-btn-hover-bg: var(--danger-400) !important;
}

.btn-warning {
  --bs-btn-color: #fff !important;

  --bs-btn-bg: var(--warning) !important;
  --bs-btn-border-color: var(--warning) !important;
  --bs-btn-hover-border-color: var(--warning-400) !important;
  --bs-btn-hover-bg: var(--warning-400) !important;
}

.btn-outline-primary {
  --bs-btn-color: var(--primary) !important;
  --bs-btn-border-color: var(--primary) !important;
  --bs-btn-hover-border-color: var(--primary-400) !important;
  --bs-btn-hover-bg: var(--primary-400) !important;
}

.btn-outline-secondary {
  --bs-btn-color: var(--secondary) !important;
  --bs-btn-border-color: var(--secondary) !important;
  --bs-btn-hover-border-color: var(--secondary-400) !important;
  --bs-btn-hover-bg: var(--secondary-400) !important;
}

.add-more-btn {
  font-size: 20px;
  cursor: pointer;
  color: var(--accent);
  font-weight: 500;
  margin-block-end: 20px;
  width: fit-content;
}
