@use './globals/colors' as *;
@use './globals/typography' as *;

$primary: $c-primary;
$secondary: $c-secondary;
$danger: $c-danger;
$warning: $c-warning;
$info: $c-info;
$success: $c-success;

$font-family-sans-serif: 'Roboto', sans-serif;
:root {
  --bs-btn-active-color: #fff !important;
}
@import '../../../node_modules/bootstrap/scss/bootstrap.scss';
