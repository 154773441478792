@use '../../assets/scss/util' as *;
@use '../../assets/scss/globals' as *;

.l-sidebar {
  display: grid;
  gap: 10px;

  @include breakpoint(res1024) {
    padding-block-start: 35px;
  }

  footer {
    position: fixed;
    bottom: 0;
    width: 100%;

    @include breakpoint(res1024) {
      all: unset;
      padding-top: 5px;
    }
  }

  @include breakpoint(res1024) {
    grid-template-columns: 80px auto;

    footer {
      grid-column: 1 / span 2;
    }
  }

  &--staff {
    @include breakpoint(res1024) {
      padding-block-start: 13px;
      grid-template-columns: 300px auto;
    }
  }
}
