@use '../../assets/scss/globals/' as *;
@use '../../assets/scss/util/' as *;

.white-board {
  max-width: 1846px;
  width: 100%;
  height: 90vh;
  margin: 10px auto;
  &__two-buttons {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
  }
  &__blank-box {
    width: 100%;
    min-height: 770px;
    background-color: #fdfafa;
    border: 1px solid #f3f3f3;
    border-radius: 5px;
    height: 788px;
  }
  &__video {
    width: 80%;
    min-height: 770px;
    // height: 70%;
    // margin: 2%;
  }
  &__tgl-btn {
    border: 1px solid #ccc;
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    padding: 0 5px;
    align-items: center;
    border-radius: 5px;
    input {
      clip: rect(0, 0, 0, 0);
      height: 1px;
      width: 1px;
      border: 0;
      overflow: hidden;
      &:checked + label {
        background: $c-success;
        color: white;
        font-weight: 500;
      }
    }
    label {
      background-color: var(--white);
      color: rgb(165, 173, 183);
      text-align: center;
      font-family: 'Quicksand', sans-serif;
      font-size: 0.8rem;
      padding: 6px 15px;
      border-radius: 5px;
      //   float: left;
      transition: all 0.1s ease-in-out;
      &:hover {
        cursor: pointer;
      }
    }
    // label:first-of-type {
    //   border-radius: 15.5px 0 0 15.5px;
    // }
    // label:last-of-type {
    //   border-radius: 0 15.5px 15.5px 0;
    // }
  }
}
