@use '../util' as *;
@use './colors' as *;
:root {
  --roboto: 'Roboto', sans-serif;
  --inter: 'Inter', sans-serif;
  --DM-Serif: 'DM Serif Display', serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

.main-heading {
  font-size: 40px;
}

.heading {
  font-size: 30px;
}

.small-heading {
  font-size: 20px;
}

.text {
  font-size: 16px;
}

.small-text {
  font-size: 14px;
}

.f-roboto {
  font-family: var(--roboto);
}

.f-inter {
  font-family: var(--inter);
}

.f-dm {
  font-family: var(--DM-Serif);
}

$fontWeight: (200, 300, 400, 500, 600, 700, 800, 900);

@each $item in $fontWeight {
  .fw-#{$item} {
    font-weight: #{$item};
  }
}
