@use './breakpoints' as *;
@use '../../scss/globals/colors.scss' as *;

@use 'sass:math';

@function rem($pixels, $context: 16) {
  @return (math.div($pixels, $context)) * 1rem;
}

.l-flex {
  display: flex;
}

.l-grid {
  display: grid;
}

$flexSpace: (
  'center',
  'start',
  'end',
  'flex-start',
  'flex-end',
  'space-between',
  'space-around',
  'space-evenly'
);

@each $item in $flexSpace {
  .justify-content-#{$item} {
    justify-content: #{$item};
  }

  .align-item-#{$item} {
    align-items: #{$item};
  }

  .align-self-#{$item} {
    align-self: #{$item};
  }
}

$numberOfCal: (2, 3, 4, 5, 6, 7, 8, 9, 10);

@each $item in $numberOfCal {
  .g-col-#{$item} {
    grid-template-columns: repeat(#{$item}, 1fr);
  }
}

@mixin use-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin use-grid {
  display: grid;
  justify-content: center;
  align-items: center;
}

@mixin card {
  width: 318px;
  height: 118px;
  border-radius: 10px;
  padding: 15px;
  border: 1px solid transparent;
}

@mixin upload-label {
  @include use-flex();
  height: 102px;
  padding: 14px 45px;
  background: var(--success-100);
  color: var(--text-muted);
  font-size: 20px;
  font-weight: 500;
  transition: all 0.4s;
  cursor: pointer;
  text-align: center;
  border: 1px dotted var(--success);
  span {
    color: var(--success);
    padding: 0 5px;
  }
}

@mixin btn-upload {
  position: absolute;
  top: 35px;
  left: 0;
  width: 100%;
  height: 102px;
  opacity: 0;
  padding: 14px 0;
  cursor: pointer;
}
@mixin page-height {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin common-card-padding {
  padding: 30px;

  @include breakpoint(res1024) {
    padding: 50px 45px 0px 45px;
  }
  @include breakpoint(res1920) {
    padding: 60px 85px 0px 85px;
  }
}
@mixin common-card-heading {
  color: var(--primary);
  margin-bottom: 30px;
  font-size: 25px;
  font-family: var(--DM-Serif);
  font-weight: 400;

  @include breakpoint(res375) {
    font-size: 32px;
  }
  @include breakpoint(res768) {
    font-size: 40px;
  }

  @include breakpoint(res1024) {
    font-size: 34px;
    margin-top: 80px;
  }

  @include breakpoint(res1440) {
    font-size: 50px;
    line-height: 50px;
  }
}
