@use '../../../assets/scss/globals/' as *;
@use '../../../assets/scss/util/' as *;

.student-video {
  max-width: 1846px;
  width: 100%;
  height: 90vh;
  margin: 10px auto;
  .active {
    border: 3px solid #ff7a00;
    border-radius: 5px;
  }
  &__wrapper {
    position: relative;
    border: 3px solid transparent;
    border-radius: 5px;
    margin-bottom: 25px;
    &:hover {
      .student-video__remove,
      .student-video__publisher {
        opacity: 1;
      }
    }
  }
  &__teacher-wrapper {
    position: relative;
    border: 6px solid green;
    border-radius: 5px;
    margin-bottom: 25px;
  }
  &__teacher-name {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #008000b5;
    padding: 5px 10px;
    p {
      margin: 0;
      color: var(--white);
      font-size: 12px;
      font-weight: 500;
    }
  }
  &__chat-dots {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px 15px;
  }
  &__wave {
    position: relative;
    text-align: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: green;
    display: flex;
    justify-content: center;
    align-items: center;
    .dot {
      display: inline-block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      margin-right: 1px;
      background: var(--white);
      animation: wave 1.3s linear infinite;
      &:nth-child(2) {
        animation-delay: -1.1s;
      }
      &:nth-child(3) {
        animation-delay: -0.9s;
      }
    }
  }
  &__remove {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 8px 0 0 10px;
    opacity: 0;
  }
  &__remove-btn {
    .btn-danger {
      padding: 3px 10px;
      font-size: 12px;
      font-weight: 500;
      border-radius: 7px 0 0 7px;
    }
  }
  &__student-name {
    position: absolute;
    bottom: 32px;
    width: 100%;
    background: #0000009c;
    padding: 5px 10px;
    p {
      margin: 0;
      color: var(--white);
      font-size: 12px;
      font-weight: 500;
    }
  }
  &__publisher {
    position: absolute;
    width: 100%;
    background-color: transparent;
    bottom: 61px;
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    opacity: 0;
    .btn-success {
      border-radius: 7px 0 0 7px;
      font-size: 12px;
      font-weight: 500;
      padding: 3px 15px;
    }
    .hand {
      animation-name: wave-animation;
      animation-duration: 2.5s;
      animation-iteration-count: infinite;
      transform-origin: 70% 70%;
      display: inline-block;
    }
  }
  &__control-buttons {
    background-color: $c-primary;
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 5px 20px;
    display: flex;
    justify-content: space-between;
    svg {
      fill: var(--white);
      width: 22px;
      height: 22px;
    }
  }
}
// Dots Animation
@keyframes wave {
  0%,
  60%,
  100% {
    transform: initial;
  }

  30% {
    transform: translateY(-5px);
  }
}
// Hand Animation
@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

// Media Query Starts

@media (max-width: 414px) {
  .student-video {
    &__publisher {
      .btn-success {
        padding: 3px 5px;
      }
    }
    .col-sm-6 {
      width: 50%;
    }
  }
}
