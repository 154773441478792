@use '../util' as *;
@use './colors' as *;

body {
  background-color: var(--white) !important;
}

.hover {
  &:hover {
    cursor: pointer;
  }
}

// Dashboard Page Cards
.yellow-card {
  @include card();
  background: var(--gradient-warning);
  &:hover {
    border: 1px solid var(--warning);
    .btn-link {
      color: var(--warning);
      text-decoration: underline;
    }
  }
  h4 {
    color: var(--warning);
  }
}
.blue-card {
  @include card();
  background: var(--gradient-info);
  &:hover {
    border: 1px solid var(--info);
    .btn-link {
      color: var(--info);
      text-decoration: underline;
    }
  }
  h4 {
    color: var(--info);
  }
}
.purple-card {
  @include card();
  background: var(--gradient-primary);
  &:hover {
    border: 1px solid var(--primary);
    .btn-link {
      color: var(--primary);
      text-decoration: underline;
    }
  }
  h4 {
    color: var(--primary);
  }
}
.green-card {
  @include card();
  background: var(--gradient-success);
  &:hover {
    border: 1px solid var(--success);
    .btn-link {
      color: var(--success);
      text-decoration: underline;
    }
  }
  h4 {
    color: var(--success);
  }
}
.red-card {
  @include card();
  background: var(--gradient-danger);
  &:hover {
    border: 1px solid var(--danger);
    .btn-link {
      color: var(--danger);
      text-decoration: underline;
    }
  }
}

//checkbox and radio buttons
.form-check {
  display: flex;
  margin-top: 60px;
  margin-bottom: -20px;
  @include breakpoint(res768) {
    margin-top: 40px;
  }
}
.form-check-input {
  padding: 15px;
  border: 1px solid var(--primary);

  &:focus {
    border: 1px solid var(--primary);
  }
  &:checked {
    background-color: var(--primary) !important;
    border: 1px solid var(--primary) !important;
  }
}
.form-check-input[type='radio'] {
  border-radius: 50% !important;
  background-color: var(--white) !important;
  border: 2px solid var(--primary) !important;

  &:checked[type='radio'] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgb(156, 103, 223)'/%3e%3c/svg%3e");
    background-color: --white !important;
    border: 2px solid var(--primary) !important;
  }
}
.form-check-label {
  margin-left: 14px;
  margin-top: 8px;

  // @include breakpoint(res768) {
  //   margin-top: 6px;
  // }
}
.submit-btn-margin {
  margin: 40px 0px 40px 0px;
  @include breakpoint(res1024) {
    margin: 120px 0px 40px 0px;
  }
}
// Dashboard Page Card Wrapper Divider
.card-wrapper-divider {
  width: 100%;
  // height: 1px;
  // background-color: var(--text-dark);
  margin: 20px 0;
}

// Form Elements Styling
label {
  font-size: 18px;
  font-weight: 400;
  font-family: var(--roboto);
  color: var(--text-gray);
}
.form-select {
  color: var(--text-muted) !important;
}
input,
select,
textarea {
  height: 47px;
  border-radius: 5px !important;
  color: var(--text-muted-dark) !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  border-color: var(--text-gray) !important;

  &:focus {
    box-shadow: none !important;
    border-color: var(--text-gray) !important;
  }
}

textarea {
  height: unset !important;
}

//remove spinbtn from input number..
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  /* Edge */
  color: var(--text-muted) !important;
}
//autofill css
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--white) inset !important;
  -webkit-text-fill-color: var(--text-muted-dark) !important;
  font-size: 18px !important;
}
.radio-l-flex {
  display: flex;
  flex-direction: column;
  label {
    font-size: 18px;
    margin-top: 5px;
    margin-left: 35px;
    @include breakpoint(res1366) {
      font-size: 22px;
    }
  }
}

// Accordion Styling
.accordion {
  .accordion-item {
    border: none !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-bottom: 30px;
    button {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
      background-color: var(--primary-100) !important;
      color: var(--text-muted-dark);
      font-size: 25px;
      font-weight: 500;
      font-family: var(--roboto);
      &:focus {
        box-shadow: none !important;
      }
    }
    .accordion-body {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

//Accordion for modals..
.accordion--success {
  .accordion-item {
    button {
      background-color: var(--success-100) !important;
    }
  }
}

sup {
  color: var(--warning);
  font-weight: bold;
  font-size: 15px;
}
span.note {
  font-size: 12px;
  font-weight: 400;
  font-family: var(--roboto);
  color: var(--text-muted) !important;
  display: flex;
  align-items: center;
  margin-top: 5px;
  padding-left: 15px;
  i {
    color: var(--secondary);
    font-size: 20px;
    margin-right: 5px;
  }
}
//eye icon
.eye-icon {
  position: absolute;
  right: 5%;
  top: 53%;
  font-size: 30px;
  color: var(--primary);
}
.pos-relative {
  position: relative;
}

.br-5 {
  border-radius: 5px !important;
}
.calender {
  color: var(--grey-color) !important;
}

@keyframes swing {
  0%,
  30%,
  50%,
  70%,
  100% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(10deg);
  }

  40% {
    transform: rotate(-10deg);
  }

  60% {
    transform: rotate(5deg);
  }

  80% {
    transform: rotate(-5deg);
  }
}

// react toast style
.Toastify {
  z-index: 999;
}

.l-flex {
  display: flex !important;
}

.justify-space-between {
  justify-content: space-between !important;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

// layouts css
.l-with-button {
  display: flex;
  gap: 20px;
  &__arrow-heading {
    margin-block: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 13px;
    h3 {
      margin: unset;
      color: var(--primary);
      font-size: 30px;
      font-weight: 400;
      font-family: var(--DM-Serif);
      word-wrap: break-word;
    }
    i {
      color: var(--primary);
      font-size: 35px;
    }
    .back-separator {
      background: var(--grey);
      width: 1px;
      height: 25px;
    }
  }

  &__search-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 15px;
    flex-direction: column;
    margin-bottom: 30px;

    @include breakpoint(res768) {
      flex-direction: row;
    }

    &--center {
      align-items: flex-start;

      @include breakpoint(res768) {
        align-items: center;
      }
    }
  }

  &__search-container--semester {
    margin-block: 15px;
  }

  &__search-bar {
    form {
      width: 250px;
      ::-webkit-input-placeholder {
        /* Edge */
        color: var(--primary) !important;
      }
      input {
        width: 100%;
        height: 45px;
        border: none;
        border-radius: 30px !important;
        font-size: 16px;
        background: var(--primary-100) url(../../images/search-icon.webp);
        background-repeat: no-repeat;
        background-position: 15px center;
        padding: 0 0 0 55px;
        outline: none;
      }
    }
  }

  &__left {
    width: 100%;
    margin-right: 15px;
  }
}

.l-with-button--staff {
  display: grid;
  gap: rem(0);

  @include breakpoint(res1024) {
    display: flex;
  }
}

//model close btn ..
.btn-close {
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fc0'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  filter: brightness(0) invert(1);
  border: 2px solid var(--white);
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
// off-canvas
.home-offcanvas {
  width: 650px !important;

  &.offcanvas-end {
    border: 0px !important;
  }
  .offcanvas-header {
    background-color: var(--success);
    padding: 25px 40px;
  }
  .offcanvas-title {
    color: var(--white);
    font-size: 30px;
    font-weight: 600;
    font-family: var(--inter);
  }
  .offcanvas-body {
    position: relative;
    h4 {
      font-size: 18px;
      font-weight: 500;
      font-family: var(--roboto);
      color: var(--text-dark);
      text-transform: capitalize;
    }
  }
  .add-faculty-form-wrapper {
    width: 95%;
    margin: 0 auto;

    &__form-add-faculty {
      margin-top: 30px;

      .btn-container {
        position: absolute;
        bottom: 53px;
        right: 50px;
        display: flex;
        gap: 20px;
        align-items: center;
        margin-block-start: 50px;
      }
    }
  }

  form {
    display: grid;
    gap: 10px;
  }
}

.home-offcanvas--large {
  width: 950px !important;

  .form-wrapper {
    width: 95%;
    margin: 0 auto;

    .form-group-container {
      display: grid;
      gap: 10px;
      margin-block: 1.5rem;
      @include breakpoint(res768) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
    .topic-container {
      padding: 1.5rem;
      border: 1px solid var(--text-gray);
    }
  }
}

.subject-card-close-icon {
  display: flex;
  justify-content: end;
  svg {
    cursor: pointer;
  }
}

.topic-area {
  position: relative;

  &__close-btn {
    position: absolute;
    top: 50% !important;
    right: 15px;
    svg {
      cursor: pointer;
    }
  }
}

// Classroom Offcanvas Design Style
.classroom-offcanvas {
  width: 950px !important;
  .offcanvas-header {
    background-color: var(--success);
    padding-left: 50px;
  }
  .offcanvas-title {
    color: var(--white);
    font-size: 25px;
    font-weight: 600;
    font-family: var(--roboto);
  }
  .offcanvas-body {
    padding: 0 0 30px 0;
  }
}

.form-label small {
  color: var(--text-grey);
  font-family: Roboto;
  font-size: 16px;
}

//form-group style..
.form-group {
  .form-label {
    margin: unset;
  }
  .form-control,
  .form-check {
    margin-bottom: 30px;
  }
  .form-control.is-invalid,
  .form-check.is-invalid {
    margin-bottom: unset;
  }
  .invalid-feedback {
    margin-bottom: 5px;
  }

  &:has(.form-control.is-invalid) {
    .eye-icon,
    .note {
      display: none;
    }
  }

  &--select {
    .form-control,
    .form-check {
      margin-bottom: 0px;
    }
    .note {
      padding-left: 5px;
      text-transform: capitalize;
    }
  }
}

//demo-form..
.demo-form {
  margin-block: 5rem;
  padding: 2rem;
  max-width: 800px;
  border: 1px solid var(--primary);
  border-radius: 5px;
}

.add-option {
  margin-left: 10px;
  text-decoration: none;
}

//Directory page styling..
.p-directory {
  padding-block: 20px;

  @include breakpoint(res425) {
    padding: 10px 20px;
  }

  &__header {
    h2 {
      color: var(--primary);
      font-size: 30px;
      font-family: var(--roboto);
      text-transform: capitalize;
    }
  }

  &__search-area {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 30px;

    @include breakpoint(res768) {
      justify-content: space-between;
      flex-direction: row;
    }

    .btn-area {
      margin: auto 0;
      .btn-outline {
        width: 240px;
      }
    }
    .search-bar-area {
      display: grid;
      gap: 10px;
      align-items: center;

      @include breakpoint(res425) {
        grid-template-columns: 1fr 1fr;
      }

      input {
        height: 50px;
        max-width: 255px;
        border: none;
        border-radius: 30px !important;
        font-size: 16px;
        background: var(--primary-100) url(../../images/search-icon.webp);
        background-repeat: no-repeat;
        background-position: 15px center;
        padding: 0 0 0 55px;
        outline: none;
      }

      ::placeholder {
        color: var(--primary);
        font-size: 16px;
        font-weight: 400;
        font-family: var(--inter);
      }
      :-ms-input-placeholder {
        color: var(--primary);
        text-align: center;
      }
      ::-ms-input-placeholder {
        color: var(--primary);
        text-align: center;
      }
    }

    .css-13cymwt-control,
    .css-t3ipsp-control {
      width: 234px;
      height: 53px;
      border-radius: 5px;
      border: 2px solid var(--primary) !important;
      box-shadow: none;
      color: var(--primary);
      outline: none;
    }
  }

  &__data-table {
    table {
      thead th {
        background-color: #e7e7e7 !important;
        color: var(--primary) !important;
        text-transform: uppercase !important;
        font-family: var(--roboto) !important;
        font-size: 20px !important;
        font-weight: 500 !important;
      }
      tr {
        border-color: transparent !important;
      }

      td:first-child {
        font-family: var(--roboto);
        font-size: 16px;
        font-weight: 500;
        color: var(--text-muted);
        text-transform: capitalize;

        @include breakpoint(res425) {
          font-size: 20px;
        }
      }

      * + td {
        font-family: var(--roboto);
        font-size: 20px;
        font-weight: 500;
        color: var(--text-muted);
        text-transform: capitalize;
        padding-top: 25px;
        padding-left: 8px;
      }

      td.overview {
        span {
          color: var(--accent);
          cursor: pointer;
          font-size: 16px;
          border-radius: 5px;
          border: 1px solid var(--accent);
          padding: 10px;
          &:hover {
            opacity: 0.8;
          }

          @include breakpoint(res425) {
            font-size: 20px;
          }
        }
      }
    }

    td,
    th {
      &:nth-child(2),
      &:nth-child(3) {
        display: none;

        @include breakpoint(res1024) {
          display: table-cell;
        }
      }
    }
  }
}

//import from directory tab style..
.import-directory-tab {
  display: grid;
  gap: 10px;
  margin: 1rem 15px 1rem 0;

  @include breakpoint(res425) {
    margin: 1rem 50px 1rem 0;
  }

  &__search-area {
    padding-block: 1rem;

    input {
      height: 50px;
      width: 100%;
      border: none;
      border-radius: 30px !important;
      font-size: 16px;
      background: var(--primary-100) url(../../images/search-icon.webp);
      background-repeat: no-repeat;
      background-position: 15px center;
      padding: 0 0 0 60px;
      outline: none;
    }
  }
  &__select-all-area {
    display: flex;
    justify-content: space-between;
    padding: 0.2rem 2rem;

    .add-more-btn {
      margin-block-end: 0;
    }

    .select-box {
      font-size: 20px;
      font-weight: 400;
      color: var(--text-gray);
    }
  }

  &__table-area {
    table {
      tr {
        border-color: transparent !important;
        margin-bottom: 1rem;
      }
      .fa-user-circle-o {
        font-size: 4rem;
      }
      td:first-child {
        font-family: var(--roboto);
        font-size: 16px;
        font-weight: 400;
        color: var(--text-muted);
        text-transform: capitalize;
        padding: 25px 0 0px 8px;

        @include breakpoint(res425) {
          font-size: 20px;
        }
      }

      td.user-area {
        font-family: var(--roboto);
        font-size: 16px;
        font-weight: 400;
        color: var(--text-muted);
        text-transform: capitalize;
        display: flex;
        gap: 20px;
        padding: 10px;
        margin-inline-start: 30px;

        .details {
          display: grid;
          align-items: center;

          .name {
            font-weight: 500;
          }
        }
      }

      * + td {
        font-family: var(--roboto);
        font-size: 20px;
        font-weight: 500;
        color: var(--text-muted);
        text-transform: capitalize;
        padding: 25px 0 1rem 8px;
        border-color: var(--text-gray) !important;
      }
    }

    td {
      &:nth-child(3),
      &:nth-child(4) {
        display: none;

        @include breakpoint(res1024) {
          display: table-cell;
        }
      }
    }
  }

  .container {
    display: flex;
    gap: 20px;
    width: fit-content;
  }
  .round {
    position: relative;
  }

  .round label {
    background-color: #fff;
    border: 1px solid var(--success);
    border-radius: 50%;
    cursor: pointer;
    height: 28px !important;
    left: 0;
    position: absolute;
    top: 0;
    width: 28px;
  }

  .round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: '';
    height: 6px;
    left: 7px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
  }

  .round input[type='checkbox'] {
    visibility: hidden;
  }

  .round input[type='checkbox']:checked + label {
    background-color: #66bb6a;
    border-color: #66bb6a;
  }

  .round input[type='checkbox']:checked + label:after {
    opacity: 1;
  }
}

.popover {
  border: none !important;
  .popover-body {
    background-color: var(--primary-100);
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    ul {
      padding: 0;
      margin: 0;
      li {
        list-style: none;
        border-bottom: 1px solid var(--text-dark);
        .btn-link {
          text-decoration: none;
          color: var(--primary);
          font-size: 16px;
          font-weight: 400;
          font-family: var(--roboto);
        }
        .del {
          color: var(--warning);
        }
      }
    }
  }
}

//Document-page styling..
.p-document {
  margin-block-start: 10px;

  @include breakpoint(res1024) {
    margin-block-start: unset;
  }

  &__heading {
    display: flex;
    align-items: center;
    gap: 13px;
    color: var(--primary);
    margin-block-end: 1rem;

    .fa-angle-left {
      font-size: 35px !important;
    }
    .back-separator {
      background: var(--grey);
      width: 1px;
      height: 25px;
    }
    h2 {
      font-size: 30px;
      font-family: var(--DM-Serif);
      margin-bottom: 0;
    }
  }

  &__documents {
    display: grid;
    gap: 1rem;

    .documents-area {
      background-color: var(--primary-100);
      padding: 2rem;
      border-radius: 0.2rem;

      .doc-container {
        display: grid;
        gap: 2rem;
        text-transform: capitalize;

        @include breakpoint(res768) {
          grid-template-columns: 1fr 1fr 1fr;
        }
      }
    }
  }
  &__personal-info {
    .accordion-body {
      display: grid;
      gap: 20px;

      @include breakpoint(res425) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include breakpoint(res1024) {
        grid-template-columns: repeat(3, 1fr);
      }
      @include breakpoint(res1440) {
        grid-template-columns: repeat(5, 1fr);
      }
    }
  }

  //tabs styling..
  .nav-tabs {
    border: 0 !important;
    margin-block-end: 20px;
  }

  .nav-link.active {
    border-bottom: 4px solid var(--accent) !important;
    color: var(--accent) !important;
  }
  .nav-link:disabled {
    color: var(--text-grey) !important;
  }
  .nav-link {
    border: 0 !important;
    color: var(--text-grey);
    font-size: 20px;
    font-weight: 500;
    font-family: var(--roboto);

    &:hover {
      color: var(--accent);
    }
  }
}

//Overview-page styling..
.p-overview {
  margin-block-start: 1rem;

  @include breakpoint(res1024) {
    margin-block-start: 5px;
  }

  &__heading {
    margin-block-end: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;

    @include breakpoint(res425) {
      flex-direction: row;
      align-items: center;
    }

    h2 {
      font-size: 22px;
      font-family: var(--DM-Serif);
      color: var(--primary);
      text-align: center;
      margin: 0;
      display: flex;
      align-items: center;
      gap: 5px;

      @include breakpoint(res768) {
        font-size: 30px;
      }

      .back-icon-area {
        display: flex;
        align-items: center;
        gap: 13px;
        color: var(--primary);

        .fa-angle-left {
          font-size: 35px !important;
        }
        .back-separator {
          background: var(--grey);
          width: 1px;
          height: 25px;
        }
      }
    }

    .btn {
      padding: 10px;
      @include breakpoint(res768) {
        padding: 8px 23px 8px 23px;
      }
    }
  }

  &__profile {
    .details-area {
      display: grid;
      gap: 20px;
      padding: 2rem;
      background-color: var(--primary-100);
      border-radius: 0.2rem;

      @include breakpoint(res1024) {
        grid-template-columns: 3fr 1fr;
      }
      @include breakpoint(res1440) {
        grid-template-columns: 2fr 1fr;
      }

      .details {
        display: grid;
        gap: 20px;
        align-items: center;

        @include breakpoint(res1024) {
          grid-template-columns: 1fr 3fr;
        }

        @include breakpoint(res1440) {
          grid-template-columns: 1fr 4fr;
        }

        h4 {
          font-size: 16px;
        }

        .image-area {
          text-align: center;

          @include breakpoint(res425) {
            text-align: start;
          }
          img {
            width: 90%;
            max-width: 150px;
            max-height: 250px;
            border: 10px solid var(--white);
          }
        }

        .profile-name {
          color: var(--primary);
          text-transform: capitalize;
        }

        .info {
          display: grid;
          gap: 10px;

          @include breakpoint(res768) {
            grid-template-columns: 1.5fr 1fr;
          }
          @include breakpoint(res1440) {
            grid-template-columns: 2fr 2fr 1fr;
          }

          input {
            border: none;
            outline: none;
            width: 60%;
            background: transparent;
            height: 20px;
            font-size: 14px !important;
          }

          label {
            font-size: 14px !important;
          }
        }
      }
    }

    .documents-area {
      background-color: var(--primary-100);
      padding: 2rem;
      border-radius: 0.2rem;
      margin-block-start: 20px;

      .btn {
        padding-inline: 18px;
      }

      span {
        padding: 0.3rem 0.5rem;
        border-radius: 0.3rem;
        background-color: var(--primary);

        .fa {
          color: var(--white);
        }
      }

      .doc-container {
        display: grid;
        gap: 20px;

        @include breakpoint(res768) {
          grid-template-columns: 1fr 1fr 1fr;
        }
      }
    }
  }

  &__material-upload {
    padding-block: 1rem;
    display: grid;
    gap: 10px;
  }
}

.popover {
  --bs-popover-zindex: 1043;
  --bs-popover-bg: var(--primary);
}

.popover .popover-body ul li {
  border: none !important;
}

.c-table {
  table {
    thead th {
      background-color: #f1f1f1;
      color: var(--primary);
      text-transform: uppercase;
      font-family: var(--roboto);
      font-size: 20px;
      font-weight: 500;
    }
    tr {
      border-color: transparent !important;
    }
    td.sub-name {
      font-size: 20px;
    }
    td {
      font-family: var(--roboto);
      font-size: 16px;
      font-weight: 500;
      color: var(--text-muted);
      padding-left: 10px;
      span.compulsory {
        background-color: var(--primary-100);
        color: var(--primary);
        padding: 5px;
        margin-right: 10px;
      }
    }
    td.green {
      color: var(--accent);
    }
    td.purple {
      color: var(--primary);
    }
    button {
      &:focus {
        border: none !important;
      }
      i {
        color: var(--primary);
        font-size: 25px;
      }
    }
  }
}

.btn-container {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-block: 20px;
  justify-content: end;
}
