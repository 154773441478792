.filePreview {
    width: 100%;
    height: 8vh;
     overflow: hidden;
     object-fit: cover;
    }

    .flex-container {
        display: flex;
        width: 100%;
        
      }
  
      .flex-item {
        flex: 1;
        // border: 1px solid #ccc;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
      }
  
      .flex-item:nth-child(1) {
        flex: 0 0 20%;
      }
  
      .flex-item:nth-child(2) {
        flex: 0 0 60%;
      }
  
      .flex-item:nth-child(3) {
        flex: 0 0 20%;
      }
      .fragment {
        font-size: 12px;
        font-family: tahoma;
        height: 140px;
        border: 1px solid #ccc;
        color: #555;
        display: block;
        padding: 10px;
        box-sizing: border-box;
        text-decoration: none;
    }
    
    .fragment:hover {
        box-shadow: 2px 2px 5px rgba(0,0,0,.2);
    
    }
    
    .fragment img { 
        float: left;
        margin-right: 10px;
    }
    
    
    .fragment h3 {
        padding: 0;
        margin: 0;
        color: #369;
    }
    .fragment h4 {
        padding: 0;
        margin: 0;
        color: #000;
    }
    #close {
        float:right;
        display:inline-block;
        padding:2px 5px;
        background:#ccc;
    }
    
    #close:hover {
      float:right;
      display:inline-block;
      padding:2px 5px;
      background:#ccc;
      color:#fff;
    }
    #title {
        overflow: hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    width:150px;
    display:inline-block;
    }