.whiteboard {
  background-color: rgb(244, 245, 255);
  background-size: 40px 40px;  
  letter-spacing: 0.6px;
  font-family: Arial;
}

.canvas-container {width: 100% !important; height: 100% !important;}

.left-menu {    
  position: fixed;
  left: 10px;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 99999;
  text-transform: uppercase;
  font-size: 14px;
}

.left-menu>div {
  padding: 12px;
  margin-bottom: 10px;
}

.bottom-menu {  
  position: fixed;
  left: 50%;
  bottom: 10px;
  transform: translate(-50%, 0);
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  z-index: 99999;
}

.whiteboard button {
  background-color: inherit;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;  
  padding: 12px;
  cursor: pointer;
  transition: opacity 0.25s;
}

button:hover {opacity: 0.8;}

button:active, button:focus, button:target{
  background-color: black;
  color: #fff;
}

input[type="number"] {  
  font-size: 14px;
  padding: 0 0 0 10px;
  width: 70px;
  outline: none;
}

.shadow {box-shadow: 0 0 0 1px rgb(255 255 255 / 24%), 1px 1px 5px rgb(0 0 0 / 22%);}

.w-100 {width: 100%;}
.h-100 {height: 100%;}

.bg-white {background-color: #fff;}

.border-0 {border: 0;}

.d-flex {display: flex;}
.flex-column {flex-direction: column;}

.justify-between {justify-content: space-between;}
.justify-center { justify-content: center;}
.align-center {align-items: center;}
.align-end {align-items: flex-end;}

.br-7 {border-radius: 7px;}

.mb-10 {margin-bottom: 10px;}
.mr-10 {margin-right: 10px;}

.pr-1 {padding-right: 12px;}
.pl-1 {padding-left: 12px;}
.py-1 {padding-top: 12px; padding-bottom: 12px;}