@use '../../assets/scss/globals/' as *;
@use '../../assets/scss/util/' as *;

.class-list {
  &__online-list {
    .table-responsive {
      height: 750px;
      overflow-y: scroll;
    }
    width: 100%;
    margin-top: 50px;
    h3 {
      background: $c-success;
      text-align: center;
      color: var(--white);
      font-size: 25px;
      font-weight: 500;
      padding: 8px;
      margin-bottom: 20px;
    }
    .table {
      thead {
        tr {
          th {
            background: $c-primary;
            color: var(--white);
            font-size: 20px;
            font-weight: 400;
          }
        }
      }
    }
    
  }
  &__questionListFooter {
    width: 100%;
    height: 15vh;
    overflow-y: scroll;
  }
  &__options {
    
    height: 15vh;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

// Media Query Starts
@media (max-width: 820px) {
  .class-list {
    &__online-list {
      .table {
        thead {
          tr {
            th {
              font-size: 16px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}