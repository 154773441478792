$spaceAmounts: (
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
  60,
  61,
  62,
  63,
  64,
  65,
  66,
  67,
  68,
  69,
  70,
  71,
  72,
  73,
  74,
  75,
  76,
  77,
  78,
  79,
  80,
  81,
  82,
  83,
  84,
  85,
  86,
  87,
  88,
  89,
  90,
  91,
  92,
  93,
  94,
  95,
  96,
  97,
  98,
  99,
  100,
  105,
  110,
  115,
  120,
  125,
  130,
  135,
  140,
  145,
  150,
  155,
  160,
  165,
  170,
  175,
  180,
  185,
  190,
  195,
  200,
  205,
  210,
  215,
  220,
  225,
  230,
  235,
  240,
  245,
  250,
  255,
  260,
  265,
  270,
  275,
  280,
  285,
  290,
  295,
  300,
  305,
  310,
  315,
  320,
  325,
  330,
  335,
  340,
  345,
  350,
  355,
  360,
  365,
  370,
  375,
  380,
  385,
  390,
  395,
  400
);

@each $space in $spaceAmounts {
  .m-#{$space} {
    margin: #{$space}px !important;
  }
  .mt-#{$space} {
    margin-top: #{$space}px !important;
  }

  .mb-#{$space} {
    margin-bottom: #{$space}px !important;
  }

  .ml-#{$space} {
    margin-left: #{$space}px !important;
  }

  .mr-#{$space} {
    margin-right: #{$space}px !important;
  }

  .my-#{$space} {
    margin: #{$space}px 0 !important;
  }
  .mx-#{$space} {
    margin: 0 #{$space}px !important;
  }
  .p-#{$space} {
    padding: #{$space}px !important;
  }
  .pt-#{$space} {
    padding-top: #{$space}px !important;
  }

  .pb-#{$space} {
    padding-bottom: #{$space}px !important;
  }

  .pl-#{$space} {
    padding-left: #{$space}px !important;
  }

  .pr-#{$space} {
    padding-right: #{$space}px !important;
  }

  .py-#{$space} {
    padding: #{$space}px 0 !important;
  }
  .px-#{$space} {
    padding: 0 #{$space}px !important;
  }
  .gap-#{$space} {
    gap: #{$space}px !important;
  }
}
