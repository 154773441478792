:root {
  --white: #fff;
  --black: #000;
  --text-dark: #000;
  --text-light: #fff;
  --text-muted: #8f8f8f;
  --text-gray: #727272;
  --grey: #c1c1c1;
  --text-grey: #B9B9B9;
  --base: #fff;
  --box-shadow: rgba(237, 223, 255, 0.5);
  --bs-btn-active-color: #fff !important;
}

// defining scss variable
$c-primary: #9c67df;
$c-secondary: #ffb074;
$c-accent: #14ae5c;
$c-success: #14ae5c;
$c-warning: #ffa500;
$c-danger: #ff0000;
$c-info: #007bff;

// // function for converting scss variable to css variable
// $colors: (
//   danger: $danger,
//   background: $background,
//   secondary-purple: $secondary-purple,
// );

// // loop over each name, color
// :root {
//   // each item in color map
//   @each $name, $color in $colors {
//     --#{$name}: #{$color};
//   }
// }

// color shades generator function
$colors: (
  'secondary': $c-secondary,
  'accent': $c-accent,
  'primary': $c-primary,
  'danger': $c-danger,
  'warning': $c-warning,
  'info': $c-info,
  'success': $c-success,
);

:root {
  // each item in color map
  @each $name,
  $color in $colors {
    --#{$name}: #{$color};
    --#{$name}-100: color-mix(in srgb, var(--#{$name}), #fff 90%);
    --#{$name}-200: color-mix(in srgb, var(--#{$name}), #fff 60%);
    --#{$name}-300: color-mix(in srgb, var(--#{$name}), #fff 40%);
    --#{$name}-400: color-mix(in srgb, var(--#{$name}), #fff 20%);
    --#{$name}-600: color-mix(in srgb, var(--#{$name}), #000 20%);
    --#{$name}-700: color-mix(in srgb, var(--#{$name}), #000 40%);
    --#{$name}-800: color-mix(in srgb, var(--#{$name}), #000 60%);
    --#{$name}-900: color-mix(in srgb, var(--#{$name}), #000 80%);
    --gradient-#{$name}: linear-gradient(90deg,
      color-mix(in srgb, var(--#{$name}), #fff 80%) 2.99%,
      rgba(242, 228, 255, 0) 111.16%);
  }
}

@function set-text-color($color) {
  @if (lightness($color) >60) {
    @return $text-dark;
  }
  @else {
    @return $text-light;
  }
}

@mixin set-background($color) {
  background-color: $color;
  color: set-text-color($color);
}

.navbar {
  background-color: var(--primary-100);
}

.bg-background {
  background: var(--background) !important;
}

// .ts{
//   --primary: #14176A;
//   --secondary: #f3f3d8;
//   --accent: #3ec17b;
//   --success: #F78631;
//   --warning: #ffa500;
//   --danger: #ff0000;
//   --info: #007bff;

//   @each $name,
//   $color in $colors {
//     --#{$name}-100: color-mix(in srgb, var(--#{$name}), #fff 90%);
//     --#{$name}-200: color-mix(in srgb, var(--#{$name}), #fff 60%);
//     --#{$name}-300: color-mix(in srgb, var(--#{$name}), #fff 40%);
//     --#{$name}-400: color-mix(in srgb, var(--#{$name}), #fff 20%);
//     --#{$name}-600: color-mix(in srgb, var(--#{$name}), #000 20%);
//     --#{$name}-700: color-mix(in srgb, var(--#{$name}), #000 40%);
//     --#{$name}-800: color-mix(in srgb, var(--#{$name}), #000 60%);
//     --#{$name}-900: color-mix(in srgb, var(--#{$name}), #000 80%);
//      --gradient-#{$name}: linear-gradient(90deg,
//       color-mix(in srgb, var(--#{$name}), #fff 60%) 2.99%,
//       rgba(242, 228, 255, 0) 111.16%);
// }
// }

